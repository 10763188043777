<template>
  <AnotherUserProfileModalLoading
      :user="state.selectedUser?.user"
      :user-contact="state.selectedUser?.use"
      @close="() => state.selectedUser = null"
      style="visibility: visible"
  />

<!--  <AnotherUserProfileModalLoading-->
<!--      :user="state.selectedUser"-->
<!--      @close="() => {state.selectedUser = null}"-->
<!--      style="position: relative;z-index: 1002"-->
<!--  />-->

  <bottom-drawer
      v-if="!state.isLoading"
      :isShown="!!state.userContactShown"
      title="Удаление из визитницы"
      @close="state.userContactShown = false"
  >
    <template v-if="state.userContactShown">

      <Typography variant="v14-600" color="#D3D2D2">
        Ты действительно хочешь удалить
        {{ state.userContactShown.contact_user.first_name }} {{ state.userContactShown.contact_user.last_name }}
        из визитницы?
      </Typography>

      <div style="display: flex; gap: 4px; margin: 16px 0">
        <appButton
            full-width
            class="submitButton"
            variant="secondary"
            :disabled="state.isDeleting"
            @click="state.userContactShown = false"
        >
          Отменить
        </appButton>
        <appButton
            full-width
            class="submitButton"
            variant="secondary"
            color="#FF303A"
            :disabled="state.isDeleting"
            :loading="state.isDeleting"
            @click="() => evaluated(0, state.userContactShown)"
        >
          Удалить
        </appButton>
      </div>

    </template>
  </bottom-drawer>

  <div class="card_holder">
    <head-new
        :back-action="back"
        noSpace
    >
      <FormTextInputSearch
          searchRef="search"
          name="searchQuery"
          fullWidth
          onlyInput
          style="margin-left: 12px"
          @options="state.isFiltration = true"
          @submit="initialSearch"
          @blur="initialSearch"
      />
    </head-new>

    <template v-if="state.isSearched">
      <div v-if="!state.resultIsEmpty && !state.isFilterError" class="main_container tabs">
        <button
            :disabled="state.contacts_result.result.length === 0"
            :class="{ selected: state.activeTab === 'contacts_result' }"
            @click="changeTab('contacts_result')"
        >
          <Skeleton variant="text" v-if="state.isLoading" width="104"/>
          <template v-if="!state.isLoading">
            {{ `В визитнице • ${state.contacts_result.count}` }}
          </template>
        </button>
        <button
            :disabled="state.global_result.result.length === 0"
            :class="{ selected: state.activeTab === 'global_result' }"
            @click="changeTab('global_result')"
        >
          <Skeleton variant="text" v-if="state.isLoading" width="104"/>
          <template v-if="!state.isLoading">
            {{ `Глобально • ${state.global_result.count}` }}
          </template>
        </button>
      </div>

      <RecycleScroller
          ref="scroller"
          class="main_container holder_in"
          v-if="!state.isLoading && state[state.activeTab].result.length"
          :items="state[state.activeTab].result"
          :item-size="264"
          key-field="indexKey"
          :buffer="264"
          @scroll-end="additionalLoading"
      >
        <template #before>
          <div style="height: 16px"/>
        </template>
        <template #after>
          <div style="height: 16px"/>
        </template>

        <template v-slot="{item}">
          <UserCard
              :data="item.user ?? item.contact_user"
              :userContact="item.id ? item : null"
              style="margin-bottom: 8px"
              :profileClick="() => openUserModal(item)"
              @addContact="addToContact"
              @reEvaluate="contact => state.userContactShown = contact"
              :isBtnLoading="item.isLoading"
          />
        </template>
      </RecycleScroller>
      <div
          v-if="!state.isLoading && state.isFilterError"
          class="main_container holder_in"
      >
        <div class="listIsEmpty">
          <div class="imgNotFound"/>
          <Typography variant="body1" center color="#D3D2D2" style="padding: 0 12px">
            <template v-if="state.isFilterError === 'less'">
              Мало символов для поиска, набери хотя бы 3
            </template>
            <template v-else>
              Слишком много символов для поиска, введи не более 50
            </template>
          </Typography>
        </div>
      </div>
      <div
          v-else-if="!state.isLoading && state.resultIsEmpty"
          class="main_container holder_in"
      >
        <div class="listIsEmpty">
          <div class="imgNotFound"/>
          <Typography variant="body1" center color="#D3D2D2" style="padding: 0 12px">
            По твоему {{ state.lastSearchParams.search_query ? `«${state.lastSearchParams.search_query}»` : null }}
            запросу ничего нет. Напиши по-другому
          </Typography>
        </div>
      </div>

      <div v-if="state.isLoading" class="main_container holder_in">
        <div style="display: flex; flex-direction: column; gap: 8px;margin: 16px 0;">
          <UserCard
              v-for="i in 5"
              :data="{}"
              :isLoading="true"
              :userContact="true"
          />
        </div>
      </div>
    </template>

    <template v-if="state.isFiltration">
      <UserSearchFiltration
          :isShown="!!state.isFiltration"
          v-model:filters="state.filters"
          @close="state.isFiltration = false"
          @applyFilters="applyFilters"
      />
    </template>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import {nextTick, onMounted, reactive, ref, toRaw, watch} from 'vue'
import {appAxios} from '@/axios'
import {router} from '@/router/router'
import FormTextInputSearch from '@/components/form/FormTextInputSearch.vue'
import UserSearchFiltration from '@/views/social/UserSearchFiltration.vue'
import {useRoute} from 'vue-router'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import UserCard from '@/components/UserCard.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import lodash from 'lodash'
import Typography from '@/components/UI/Typography.vue'
import AnotherUserProfileModal from '@/views/profile/AnotherUserProfileModal.vue'
import Evalution from '@/components/Evaluation.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import store from '@/store/store.js'
import AppButton from "@/components/UI/AppButton.vue";
import AnotherUserProfileModalLoading from '@/views/profile/AnotherUserProfileModalLoading.vue'

const scroller = ref()
const route = useRoute()

const state = reactive({
  isLoading: false,
  isSearched: false,
  isDeleting: false,
  lastSearchParams: {},

  isChanged: false,

  userContactShown: false,

  resultIsEmpty: false,

  contacts_result: {
    result: [],
    lastIndex: 0,
    count: 0,
  }, // count, result, lastIndex, isAdditionalLoading: false, isAdditionalLoadingNeedMore: false,
  global_result: {
    result: [],
    lastIndex: 0,
    count: 0,
  },

  activeTab: route.meta.defaultSearch,

  isFiltration: route.params.filter,
  filters: {
    searchQuery: '',
  },
  user: null,

  selectedUser: null,
  lastIndexKey: -100e3,
})

const {handleSubmit, isSubmitting, setFieldValue, setValues, setValue, values} = useForm({
  validationSchema: Yup.object({}),
})

watch(values, value => {
  state.filters.searchQuery = value.searchQuery
})

async function back() {
  await router.push({name: route.meta.returnName})
}

function changeTab(tab) {
  state.activeTab = tab
  scroller.value?.scrollToPosition(0)
}

function applyFilters(filters) {
  state.filters = toRaw(filters)
  setValues({searchQuery: filters.searchQuery})
  state.isFiltration = false
  initialSearch()
}

function filtersToRequest(values) {
  const {searchQuery} = values
  const params = {'search_query': searchQuery ? searchQuery : undefined}
  if (values.profession) {
    params.profession = store.state.user.directories.profession_list.find(p => p.id === values.profession)?.title
  }
  if (values.niche1 || values.niche2 || values.niche3) {
    params.niche_list = [
      store.state.user.directories.niche_list.find(p => p.id === values.niche1)?.title,
      store.state.user.directories.niche_list.find(p => p.id === values.niche2)?.title,
      store.state.user.directories.niche_list.find(p => p.id === values.niche3)?.title,
    ].filter(i => i)
  }
  if (values.city1 || values.city2 || values.city3) {
    params.city_list = [
      values.city1,
      values.city2,
      values.city3
    ].filter(i => i)
  }
  return params
}

async function initialSearch() {
  const params = filtersToRequest(state.filters)
  state.isFilterError = false

  console.log('state.filters', state.filters)

  if ((!params.search_query || params.search_query.length <= 2) && Object.keys(params).length <= 1) {
    state.lastSearchParams = params
    state.isSearched = true
    state.isFilterError = "less"
    state.contacts_result = {result: [], count: 0}
    state.global_result = {result: [], count: 0}
    return
  }

  if ((!params.search_query || params.search_query.length > 50) && Object.keys(params).length <= 1) {
    state.lastSearchParams = params
    state.isSearched = true
    state.isFilterError = "more"
    state.contacts_result = {result: [], count: 0}
    state.global_result = {result: [], count: 0}
    return
  }

  if (lodash.isEqual(params, state.lastSearchParams) && !state.isChanged) {
    return
  }
  state.isChanged = false
  state.lastSearchParams = params
  state.isSearched = true
  state.isLoading = true
  try {
    const res = await appAxios.user.search(params)
    const {contacts_result, global_result} = res.data

    contacts_result.result.forEach((item, i) => item.indexKey = i)
    global_result.result.forEach((item, i) => item.indexKey = i)

    contacts_result.lastIndex = 10
    global_result.lastIndex = 10
    contacts_result.isAdditionalLoading = false
    global_result.isAdditionalLoading = false
    state.contacts_result = contacts_result
    state.global_result = global_result
    state.resultIsEmpty = state.global_result.result.length === 0 && state.contacts_result.result.length === 0
  } catch (e) {
    console.log('error', e)
  } finally {
    if (state[state.activeTab].count === 0) {
      if (state.activeTab === 'contacts_result') {
        state.activeTab = 'global_result'
      } else {
        state.activeTab = 'contacts_result'
      }
    }
    state.isLoading = false
  }
}

async function additionalLoading() {
  const info = state[state.activeTab]

  if (info.count > info.lastIndex) {
    if (info.isAdditionalLoading) {
      info.isAdditionalLoadingNeedMore = true
      return
    }
    info.result.push(...Array(10).fill(0).map((_, index) => ({
      indexKey: -index - 1,
    })))
    info.isAdditionalLoading++
    try {
      if (state.activeTab === 'contacts_result') {
        const res = await appAxios.myContacts.searchPaginated(filtersToRequest(state.filters), 10, info.lastIndex)
        res.data.results.forEach((item, i) => item.indexKey = i + info.result.length)
        info.result = [...info.result.filter(i => i.id >= 0), ...res.data.results]
      } else {
        const res = await appAxios.user.searchPaginated(filtersToRequest(state.filters), 10, info.lastIndex)
        res.data.results.forEach((item, i) => item.indexKey = i + info.result.length)
        info.result = [...info.result.filter(i => i.id >= 0), ...res.data.results]
      }
      info.lastIndex += 10
    } catch (e) {
      console.log('error', e)
    } finally {
      info.isAdditionalLoading = false
      if (info.isAdditionalLoadingNeedMore) {
        info.isAdditionalLoadingNeedMore--
        additionalLoading()
      }
    }
  }
}

onMounted(() => {
  if (!route.params.filter) {
    nextTick(() => {
      const el = document.querySelector('#searchQuery')
      if (el) {
        el.focus()
      }
    })
  }
})

async function evaluated(rate, userContact) {
  const list1 = state.contacts_result.result
  const list2 = state.global_result.result
  const found1 = list1.find(uc => uc.user?.id === userContact.contact_user.id || uc.contact_user?.id === userContact.contact_user.id)
  const found2 = list2.find(uc => uc.user?.id === userContact.contact_user.id || uc.contact_user?.id === userContact.contact_user.id)

  try {
    if (found1) {
      found1.isLoading = true
    }
    if (found2) {
      found2.isLoading = true
    }

    if (found1 || found2) {
      if (rate === 0) {
        state.isDeleting = true
        const res = await appAxios.myContacts.removeContact(userContact.id, userContact.contact_user.id)
        if (found1) {
          Object.assign(found1, {
            user: found1.contact_user ?? found1.user,
            id: null,
            user_id: null,
            rating: null,
            contact_user: null,
            isLoading: false,
          })
        }
        if (found2) {
          Object.assign(found2, {
            user: found2.contact_user ?? found2.user,
            id: null,
            user_id: null,
            rating: null,
            contact_user: null,
            isLoading: false,
          })
        }
        state.isDeleting = false
      } else {
        const res = await appAxios.myContacts.updateContact(userContact.id, rate)
        if (found1) {
          Object.assign(found1, {
            user: found1.contact_user ?? found1.user,
            id: null,
            user_id: null,
            rating: null,
            contact_user: null,
            isLoading: false,
          })
        }
        if (found2) {
          Object.assign(found2, {
            ...res.data,
            isLoading: false,
          })
        }
      }
      state.isChanged = true
    }
  } catch (e) {
    console.error(e)
  } finally {
    state.userContactShown = false
    if (found1) {
      found1.isLoading = false
    }
    if (found2) {
      found2.isLoading = false
    }
  }
}

async function addToContact(user) {
  const list1 = state.contacts_result.result
  const list2 = state.global_result.result
  const found1 = list1.find(uc => uc.user?.id === user.id || uc.contact_user?.id === user.id)
  const found2 = list2.find(uc => uc.user?.id === user.id || uc.contact_user?.id === user.id)

  try {
    if (found1) {
      found1.isLoading = true
    }
    if (found2) {
      found2.isLoading = true
    }

    if (found1 || found2) {
      const res = await appAxios.myContacts.addContact(user.id, 5)
      if (found1) {
        Object.assign(found1, {
          user: null,
          ...res.data,
        })
      }
      if (found2) {
        Object.assign(found2, {
          user: null,
          ...res.data,
        })
      }
      if (!found1) {
        const newContact = Object.assign({}, {
          ...found2,
          user: null,
          ...res.data,
          isLoading: false,
          indexKey: state.lastIndexKey--,
        })
        state.contacts_result.result = [
          newContact,
          ...state.contacts_result.result.filter(item => item !== newContact)
        ]

        state.contacts_result.count++
      }
    }

    state.isChanged = true
  } catch (e) {
    console.log(e)
  } finally {
    if (found1) {
      found1.isLoading = false
    }
    if (found2) {
      found2.isLoading = false
    }
  }
}

function openUserModal(item) {
  state.selectedUser = {
    ...item,
    user: item.user ?? item.contact_user,
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.card_holder {
  margin-bottom: -$homeLayoutPaddingBottom;
  min-height: calc(100vh - $bottomMenuOffset);
  min-height: calc(100dvh - $bottomMenuOffset);
  max-height: calc(100vh - $bottomMenuOffset);
  max-height: calc(100dvh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.holder_modal_active {
  opacity: 1;
  z-index: 101;

  .holder_modal_in {
    transform: translateY(0);
  }
}

.listIsEmpty {
  margin-top: 96px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 60px;
  padding: 0 32px;
  color: #fff;
  text-align: center;
}

.search {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
}

.tabs {
  position: relative;
  z-index: 2;
  margin-top: 18px;
  display: flex;
  padding: 0;

  button {
    width: 100%;
    background: #171717;
    height: 42px;
    color: #A6A5A5;
    font-size: 14px;
    border-bottom: 2px #494949 solid;

    &:disabled {
      color: #A6A5A5;

      &.selected {
        border-bottom: 2px #494949 solid;
        color: #FFF;
      }
    }

    &.selected {
      border-bottom: 2px #CF2E2E solid;
      color: #FFF;
    }
  }
}

.imgNotFound {
  background: url("@/assets/images/search/notFound.png") no-repeat center center;
  width: 180px;
  height: 180px;
  margin-bottom: 12px;
}

</style>
