import {init} from '@telegram-apps/sdk'
import * as telegramSDK from '@telegram-apps/sdk'
import {appAxios} from '@/axios.js'

window.appAxios = appAxios
window.telegramSDK = telegramSDK

const appTypes = {
  WEB: 'web',
  TELEGRAM: 'telegram',
}

let appType = appTypes.WEB

try {
  init()
  appType = appTypes.TELEGRAM

  // contact:
  //   firstName: "Timofey"
  //   lastName: "Timofeev"
  //   phoneNumber: "+79960722023"
  //   userId: 639275338

  // const {initData} = await telegramSDK.retrieveLaunchParams()
  // console.log('initData', initData)
  // const contactData = await telegramSDK.requestContact()
  // console.log('contactData', contactData)

  // Output:
  // {
  //   authDate: Date(...),
  //   hash: '...',
  //   contact: {
  //     firstName: '...',
  //     phoneNumber: '+38291789233',
  //   },
  // };

  // appAxios.telegram.send

  // fetch('/save-tg-contact', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     phone_number: phoneNumber,
  //     initData: initData,
  //   })
  // });

  // const removeListener = on('phone_requested', (payload, e2) => {
  //   console.log('phone_requested:', payload, e2);
  //   // const phoneNumber = response.phone_number;
  //   // const initData = Telegram.WebApp.initData;
  // });

  // console.log('ac1')
  // console.log('requestPhoneAccess', requestPhoneAccess)
  // if (requestPhoneAccess.isAvailable()) {
  //   console.log('ac2')
  //   const status = await requestPhoneAccess();
  //   // status will be 'sent' | 'cancelled' | string
  //   console.log('status', status)
  // }
} catch (e) {
  // not Telegram
}


export {appTypes, appType}
