<template>
  <div style="visibility: visible">
    <div v-if="props.files && props.files.length" class="modal">
      <head-new
          :back-action="close"
          :title="'Фотография профиля'"
      />

      <div class="main_container" style="padding: 0">
        <div class="modal-scroll-view" style="overflow: hidden">
          <VuePictureCropper
              :boxStyle="{
                width: '100%',
                height: 'calc(100dvh - 56px - 44px - 48px)',
                margin: 'auto',
              }"
              :img="pic"
              :options="{
                viewMode: 1,
                dragMode: 'move',
                aspectRatio,
              }"
              :presetMode="{
                // mode: 'fixedSize',
                // width: 2000,
                // height: 2000,
              }"
              @ready="ready"
          />
        </div>
      </div>

      <div class="main_container" style="padding: 24px 16px;">
        <AppButton
            full-width
            @click="getResult"
        >
          Готово
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, ref, watch} from 'vue'
import VuePictureCropper, {cropper} from 'vue-picture-cropper'
import HeadNew from "@/components/Head.vue"
import {useModalScrollingFix} from "@/hooks/useModalScrollingFix.js"
import {useModalHomeLayoutFix} from "@/hooks/useModalHomeLayoutFix.js"
import AppButton from "@/components/UI/AppButton.vue";

const emit = defineEmits(['onCrop', 'onClose'])

const modalScrollingFix = useModalScrollingFix()
const modalHomeLayoutFix = useModalHomeLayoutFix()

const props = defineProps({
  files: {
    type: Array,
    default: null,
  },
  aspectRatio: {
    type: Number,
    default: 1,
  },
})

const state = reactive({
  isReady: false,
})

const pic = ref('')

const result = reactive({
  dataURL: '',
  blobURL: '',
})

function reset() {
  if (!cropper) {
    return
  }
  cropper.reset()
}

async function getResult() {
  if (!cropper) {
    return
  }
  const file = await cropper.getFile({
    fileName: props.files[0].name,
  })
  emit('onCrop', file)
  close()
  reset()
}

function close() {
  if (!cropper) {
    return
  }
  emit('onClose')
  cropper.clear()
}

function ready() {
  // console.log('Cropper is ready.')
}

watch(() => props.files, () => {
  if (props.files && props.files.length) {
    modalScrollingFix.open()
    modalHomeLayoutFix.open()

    pic.value = ''
    result.dataURL = ''
    result.blobURL = ''

    setTimeout(() => {
      setTimeout(() => {
        const {files} = props

        pic.value = ''
        result.dataURL = ''
        result.blobURL = ''

        if (!files || !files.length) {
          return
        }

        const file = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          pic.value = String(reader.result)
        }
      }, 17)
    }, 17)

  } else {
    modalScrollingFix.close()
    modalHomeLayoutFix.close()
  }
})

</script>

<style lang="scss" scoped>

@import "@/assets/variables";

.modal {
  position: fixed;
  z-index: 1001;
  background: #171717;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100dvh);
  max-height: calc(100dvh);
  overflow: auto;

  display: flex;
  flex-direction: column;
}

</style>

<style>

.cropper-drag-box.cropper-move.cropper-modal {
  pointer-events: none;
}

.cropper-bg.cropper-bg {
  background: #2e2e2e;
}

.cropper-point.cropper-point,
.cropper-line.cropper-line {
  background: #FFF;
}

.cropper-view-box.cropper-view-box {
  outline: 2px solid #FFF;
  outline-color: #FFF;
}

</style>
