<template>
  <div class="userCard">
    <teleport to="#app">
      <BottomDrawer
          v-if="user"
          :isShown="state.isSelectedProduct"
          @close="state.isSelectedProduct = null"
          closeButtonOutside
      >
        <div v-if="state.isSelectedProduct" class="productInfo">
          <div
              v-if="state.isSelectedProduct.preview_image"
              class="imgWrapper full"
              :style="{height: `${((Math.min(width, 480)) / 1.8)|0}px`}"
          >
            <div
                class="img"
                :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.isSelectedProduct.preview_image))}"
            />
          </div>
          <div>
            <div class="title">
              {{ state.isSelectedProduct.title }}
            </div>
            <Typography variant="body1">
              {{ state.isSelectedProduct.description }}
            </Typography>
          </div>

          <div class="bottom drawer">
            <Avatar
                size="tiny"
                :image="user?.preview_avatar"
            />

            <div style="flex-grow: 1; flex-shrink: 1;">
              <Typography base="span" variant="body1" color="#D3D2D2">
                {{ user.first_name }}
              </Typography>
              {{ ' ' }}
              <Typography base="span" variant="body1" color="#D3D2D2">
                {{ user.last_name }}
              </Typography>
            </div>

            <AppButton
                size="small"
                @click="startChat(user.id)"
            >
              Написать
            </AppButton>
          </div>
        </div>
      </BottomDrawer>
    </teleport>

    <div
        ref="refContent"
        class="content"
        :class="classes"
    >
      <div class="userData">
        <template v-if="classes[0] === 'active'">
          <Avatar
              v-if="!isLoading"
              size="large"
              :image="imagesLinkCache.cache(user?.preview_avatar)"
              :key="user?.id"
              v-fullscreen-image="{
                imageUrl: [imagesLinkCache.cache(user?.avatar)],
                zoom: true,
              }"
          />
        </template>
        <template v-else>
          <Avatar
              v-if="!isLoading"
              size="large"
              :image="imagesLinkCache.cache(user?.avatar)"
          />
        </template>

        <Skeleton
            v-else
            variant="text"
            height="106"
            width="106"
            radius="16"
        />

        <div class="userInfo">
          <Typography v-if="!isLoading" variant="title">
            {{ user?.first_name }} {{ user?.last_name }}
          </Typography>
          <Skeleton
              v-else
              variant="text"
              height="24"
              width="200"
          />

          <AppButton
              v-if="!isLoading"
              variant="secondary"
              size="small"
              @click="() => {
              router.push({
                name: 'BrowsingUserChat',
                params: {userId:user?.id}
              })
          }"
          >
            <Icon :icon="require(`@/assets/icons/white_20_chat.svg`)"/>
            Написать
          </AppButton>
          <Skeleton
              v-else
              width="126"
              height="36"
          />

        </div>
      </div>

      <div style="display: flex; justify-content: flex-start">
        <UserLocationAndAge
            :city="user?.city"
            :birthDate="user?.birth_date"
        />
      </div>

      <UserProfessions
          class="userProfessions"
          v-if="!isLoading"
          :professions="user?.professions"
      />

      <Skeleton
          v-else
          variant="text"
          width="180"
      />

      <Typography v-if="!isLoading" preLine variant="body1">
        {{ user?.about_me }}
      </Typography>
      <Skeleton
          v-else
          v-for="i in 3"
          variant="text"
          :width="String(200 + (i % 2) * 50 + (i * 20))"
          height="14"
      />

      <BlockNiches
          ref="refBlockNiches"
          v-if="user?.niches.length"
          :key="user?.niches?.id"
          variant="light"
          :niches="user?.niches"
      />

      <BlockLookingFor
          ref="refBlockLookingFor"
          v-if="user?.looking_for.length"
          variant="light"
          :lookingFor="user?.looking_for"
          @applyForSkill="applyForSkill"
      />

      <BlockAchievements
          ref="refBlockAchievements"
          v-if="user?.achievements.length"
          variant="light"
          :achievements="user?.achievements"
      />

      <BlockProducts
          ref="refBlockProducts"
          v-if="user?.products.length"
          variant="light"
          :products="user?.products"
          :canBeObtained="true"
          @selectProduct="product => state.isSelectedProduct = product"
      />

      <BlockEducations
          ref="refBlockEducations"
          v-if="user?.education[0] && (user?.education[0].courses.length || user?.education[0].communities.length || user?.education[0].institutions.length)"
          variant="light"
          :education="user?.education"
      />

    </div>
  </div>
</template>

<script setup>

import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Skeleton from '@/components/UI/Skeleton.vue'
import Avatar from '@/components/profile/Avatar.vue'
import Typography from '@/components/UI/Typography.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import UserLocationAndAge from '@/components/UserLocationAndAge.vue'
import UserProfessions from '@/components/UserProfessions.vue'
import BlockNiches from '@/components/BlockNiches.vue'
import BlockAchievements from '@/components/BlockAchievements.vue'
import BlockLookingFor from '@/components/BlockLookingFor.vue'
import BlockProducts from '@/components/BlockProducts.vue'
import BlockEducations from '@/components/BlockEducations.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {onMounted, reactive, ref, watch} from 'vue'
import {createSendMessageByType} from '@/utils/createSendMessageByType'
import {useRoute} from 'vue-router'
import {yandexMetrika} from '@/external/yandexWebisor.js'
import {useElementVisibility} from '@vueuse/core'

const route = useRoute()

import {useWindowSize} from '@vueuse/core'
const {width, height} = useWindowSize()

const props = defineProps({
  user: Object,
  isLoading: {
    type: Boolean,
    default: true,
  },
  classes: {
    type: Array,
  },
  isMounted: false,
})

const refContent = ref(null)
const refBlockNiches = ref(null)
const refBlockLookingFor = ref(null)
const refBlockAchievements = ref(null)
const refBlockProducts = ref(null)
const refBlockEducations = ref(null)

const isVisibleBlockNiches = useElementVisibility(refBlockNiches)
const isVisibleBlockLookingFor = useElementVisibility(refBlockLookingFor)
const isVisibleBlockAchievements = useElementVisibility(refBlockAchievements)
const isVisibleBlockProducts = useElementVisibility(refBlockProducts)
const isVisibleBlockEducations = useElementVisibility(refBlockEducations)

const state = reactive({
  isMounted: false,
  isSelectedProduct: null,
  usedDeep: {},
})

watch([
  isVisibleBlockNiches,
  isVisibleBlockLookingFor,
  isVisibleBlockAchievements,
  isVisibleBlockProducts,
  isVisibleBlockEducations,
], (values) => {
  if (!state.isMounted) {
    return
  }
  if (!props.classes.includes('active')) {
    return
  }
  if (refContent.value.scrollTop === 0) {
    return
  }

  if (!state.usedDeep['0']) {
    yandexMetrika('reachGoal', 'networkingDeepView', {
      deep: 0,
    })
    state.usedDeep['0'] = true
  }

  values.forEach((value, index) => {
    const key = (index + 1).toString()
    if (value && !state.usedDeep[key]) {
      yandexMetrika('reachGoal', 'networkingDeepView', {
        deep: index + 1,
      })
      state.usedDeep[key] = true
    }
  })
})

watch(() => props.user, async user => {
  state.usedDeep = {}
})

onMounted(() => {
  setTimeout(() => {
    state.isMounted = true
  })
})

function startChat(userId) {
  const user = props.user
  const uid = createSendMessageByType.product({user, product: state.isSelectedProduct})
  router.push({
    name: 'BrowsingUserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function applyForSkill(skill) {
  const user = props.user
  const uid = createSendMessageByType.lookingFor({user, skill})
  await router.push({
    name: 'BrowsingUserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

</script>

<style lang="scss" scoped>

.userCard {
  transform-origin: top;
  transition: all 1s ease;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #222222;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  flex-grow: 1;
  display: flex;
  padding: 14px 0;
  will-change: transform;
  transform: scale(1) translateZ(0);

  //scrollbar-width: none;
  //&::-webkit-scrollbar {
  //  display: none;
  //}

  .content {
    width: 100%;
    padding: 0 12px;
    margin: -14px 0;
    overflow-y: hidden;

    &.active {
      overflow-y: auto;
    }
  }

  &.back {
    //background: red;
  }

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: .12;
    }
  }

  .backgroundWrapper {
    position: absolute;
    border-radius: 20px;
    z-index: -2;
    top: 0;
    left: 0;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .background {
      background-size: cover;
      filter: blur(50px);
      transform: translateZ(0);
      opacity: .12;
      flex-grow: 1;
      //animation: appear .7s ease forwards;
    }
  }
}

.score {
  display: flex;
  align-items: center;
}

.userData {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 12px;

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
}

.userProfessions {
  margin-bottom: 8px;
  margin-top: 12px;
}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .imgWrapper {
    width: 100%;
    height: 116px;
    border-radius: 8px;
    background: #3B3B3B;
    display: flex;

    &.full {
      margin: -16px -16px 0 -16px;
      width: calc(100% + 32px);
    }

    .img {
      width: 100%;
      //height: 50px;
      flex-shrink: 0;
      border-radius: 8px 8px 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .bottom {
    display: flex;
    gap: 8px;
    padding-top: 16px;
    margin-top: 4px;
    border-top: 1px solid #494949;
    align-items: center;

    & > * {
      flex-shrink: 0;
    }

    &.drawer {
      margin: 0 -16px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.share {
  margin-top: 20px;
  margin-bottom: 12px;
}


</style>
