<template>
  <div class="main_container">
    <Event
        :event="state.selectedEvent"
        @close="() => state.selectedEvent = null"
    />

    <!--    <BottomDrawer-->
    <!--        :isShown="state.showEventOffer"-->
    <!--        title="Размести своё мероприятие"-->
    <!--        :hasTitleClose="true"-->
    <!--        @close="() => state.showEventOffer = false"-->
    <!--    >-->
    <!--      <Typography variant="v14-400" color="#D3D2D2">-->
    <!--        Предложи своё мероприятие и после модерации оно появится на платформе-->
    <!--      </Typography>-->

    <!--      <AppButton-->
    <!--          full-width-->
    <!--          style="margin: 16px 0"-->
    <!--          @click="openExternalURL('https://forms.yandex.ru/u/6704e0283e9d08bd0233645c/'); state.showEventOffer = false"-->
    <!--      >-->
    <!--        Разместить-->
    <!--      </AppButton>-->
    <!--    </BottomDrawer>-->

    <div
        style="display: flex; justify-content: space-between; align-items: flex-start;"
    >
      <Typography variant="title" class="title">
        Календарь онлайн-школ
      </Typography>

      <AppButton
          style="margin: 4px 0 4px; padding-top: 4px"
          variant="transparent"
          size="small"
          @click="changeDisplayType"
      >
        <Icon
            v-if="state.displayType === 'list'"
            class="icon"
            width="24"
            height="24"
            :icon="require(`@/assets/icons/white_24_calendar.svg`)"
        />
        <Icon
            v-if="state.displayType === 'calendar'"
            class="icon"
            width="24"
            height="24"
            :icon="require(`@/assets/icons/white_24_folder.svg`)"
        />
      </AppButton>
    </div>

    <Typography variant="body1" color="#A6A5A5">
      События, встречи и анонсы, которые ты не можешь пропустить
    </Typography>
  </div>

  <div
      v-if="state.displayType === 'calendar'"
      class="main_container"
      style="margin-top: 12px;"
  >
    <Calendar
        v-if="!state.isLoading"
        :eventsList="state.eventsAll"
    />
  </div>

  <div
      v-if="state.displayType === 'list'"
      class="main_container"
      style="padding: 0; margin-top: 12px; overflow: hidden"
  >
    <div class="main_container">
      <swiper
          v-if="state.eventsList === null"
          :allowSlideNext="false"
          :allowSlidePrev="false"
          :slidesPerView="'auto'"
          :spaceBetween="8"
          :freeMode="true"
          class="event_swiper"
      >
        <swiper-slide class="slide">
          <EventCard
              :is-loading="true"
          />
        </swiper-slide>
        <swiper-slide class="slide">
          <EventCard
              :is-loading="true"
          />
        </swiper-slide>
      </swiper>
      <Card
          v-else-if="state.eventsList.length === 0"
      >
        <Icon
            width="100%"
            height="138"
            :icon="require(`@/assets/images/events/emptyEvents.png`)"
            style="background-size: contain; margin-bottom: 16px"
        />
        <Typography variant="subtitle3" color="#A6A5A5" center style="margin: 0 32px; min-height: 40px">
          Новые мероприятия будут совсем скоро
        </Typography>
      </Card>
      <swiper
          v-else
          :slidesPerView="'auto'"
          :spaceBetween="8"
          :freeMode="true"
          class="event_swiper"
          @slideChange="slideChange"
      >
        <swiper-slide v-for="event in state.eventsList" class="slide">
          <EventCard
              :data="event"
              @click="openEvent(event)"
          />
        </swiper-slide>
        <swiper-slide class="halfSlide">
          <div
              class="seeMore"
              @click="router.push({name: 'Events'})"
          >
            <Typography variant="v12-500" color="rgb(166, 165, 165)">
              Смотреть все мероприятия
            </Typography>
            <Typography variant="v12-500" color="#fff">
              и разместить своё
            </Typography>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import Card from '@/components/UI/Card.vue'
import Event from '@/views/HomeView/Event.vue'
import moment from 'moment/moment'
import {router} from '@/router/router.js'
import {Swiper, SwiperSlide} from 'swiper/vue'
import EventCard from '@/views/HomeView/EventCard.vue'
import Icon from "@/components/UI/Icon.vue"
import {yandexMetrika} from "@/external/yandexWebisor.js"
import Calendar from '@/views/events/Calendar.vue'

const state = reactive({
  isLoading: true,
  displayType: localStorage.getItem('HomeEventDisplayType') ?? 'list', // list, calendar
  eventsList: null,
  eventsAll: null,
  selectedEvent: null,
  swipedEventFired: false,
  showEventOffer: false,
})

function changeDisplayType() {
  if(state.displayType === 'list') {
    localStorage.setItem('HomeEventDisplayType', 'calendar')
    return state.displayType = 'calendar'
  }
  if(state.displayType === 'calendar') {
    localStorage.setItem('HomeEventDisplayType', 'list')
    return state.displayType = 'list'
  }
  state.displayType = 'list'
}

async function openEvent(event) {
  state.selectedEvent = event
  await yandexMetrika('reachGoal', 'eventOpen', {
    title: event.title,
  })
}

onBeforeMount(async () => {
  try {
    const res = await appAxios.events.fetchAll()
    const eventsList = res.data

    state.eventsAll = eventsList.sort(function (a, b) {
      return new Date(a.start_date) - new Date(b.start_date)
    })

    for (const event of state.eventsAll) {
      const {start_date, end_date} = event
      const start = moment(start_date, 'YYYY-MM-DD')
      const end = moment(end_date, 'YYYY-MM-DD')

      if (start.year() === end.year() && start.month() === end.month() && start.day() === end.day()) {
        event.dateSting = `${start.format('DD.MM')}`
      } else if (start.year() === end.year() && start.month() === end.month()) {
        event.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
      } else {
        event.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
      }
    }

    state.eventsList = state.eventsAll.slice(0, 3)
  } catch (e) {
    console.error(e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

async function slideChange() {
  if (!state.swipedEventFired) {
    state.swipedEventFired = true
    await yandexMetrika('reachGoal', 'eventsSwiped')
  }
}

</script>

<style lang="scss" scoped>

.title {
  margin: 12px 0 4px;
}

.event_swiper {
  overflow: visible;
  height: 224px;
}

.slide {
  width: calc(70%);
  min-width: 290px;
}

.halfSlide {
  width: calc(35%);
  min-width: 145px;
}

.seeMore {
  background: #2a2a2a;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 32px;
  height: 100%;
}

</style>
