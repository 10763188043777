import { createStore } from 'vuex'
import {user} from '@/store/user'
import {app} from '@/store/app'

const store = createStore({
  modules: {
    app,
    user,
  }
})

window.store = store

export default store
