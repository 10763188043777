<template>
  <div v-if="state.features">
    <div
        v-for="item in state.features[type]"
        class="item"
        :class="{[item.type]: item.type}"
        v-html="item.text"
    />
  </div>
</template>

<script setup>
import {subscriptionFeatures} from '@/configs/subscriptionStatuses.js'
import {onBeforeMount, reactive} from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'full',
  },
})

const state = reactive({
  features: null,
})

onBeforeMount(() => {
  if (subscriptionFeatures[props.title]) {
    state.features = subscriptionFeatures[props.title]
  }
})

</script>

<style lang="scss" scoped>

.item {
  margin-left: 20px;
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    top: 1px;
    left: -20px;
    background-size: cover;
    background-position: center center;
  }

  &.check {
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    &:before {
      background-image: url("@/assets/icons/whire_16_check.svg");
    }
  }
  &.plus {
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    &:before {
      background-image: url("@/assets/icons/red_16_plus.svg");
    }
  }
  &.gray {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #D3D2D2;
  }
  &.item:first-child {
    margin-top: 0;
  }
}

</style>
