<template>
  <BottomDrawer
      :isShown="state.isShown"
      title="Заполни профиль полностью"
      @close="close"
  >
    <Typography variant="v14-400" color="#D3D2D2">
      {{ data.text ?? 'Чтобы получить доступ к этой функции' }}
    </Typography>
    <AppButton
        full-width
        style="margin: 16px 0"
        @click="fill"
    >
      Заполнить
    </AppButton>
  </BottomDrawer>
</template>

<script setup>
import BottomDrawer from '@/components/BottomDrawer.vue'
import {onMounted, reactive} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import Typography from '@/components/UI/Typography.vue'
import store from '@/store/store.js'
import {router} from '@/router/router.js'

const props = defineProps({
  data: {
    type: Object,
    default: {},
  }
})

const state = reactive({
  isShown: false,
})

onMounted(() => {
  setTimeout(() => {
    state.isShown = true
    // messageComponent
  }, 50)
})

function close() {
  state.isShown = false
  store.dispatch('app/setMessageComponent', null)
}

function fill() {
  state.isShown = false
  store.dispatch('app/setMessageComponent', null)
  router.push({name: 'CheckList'})
}

</script>

<style lang="scss" scoped>

</style>
