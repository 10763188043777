import * as Yup from 'yup'
import moment from 'moment'

Yup.addMethod(Yup.string, "dateValidate", function (errorMessage) {
  return this.test(`dateValidate`, errorMessage, function (value) {

    if(!value || value === '') {
      return true
    }

    const {path, createError} = this
    const date = moment(value, "DD.MM.YYYY")

    return (value.length === 10 && date.isValid() || createError({path, message: errorMessage}))
  })
})

Yup.addMethod(Yup.string, "birthdayValidate", function (errorMessage) {
  return this.test(`birthdayValidate`, errorMessage, function (value) {

    if(!value || value === '') {
      return true
    }

    const {path, createError} = this
    const date = moment(value, "DD.MM.YYYY")

    if(!date.isSameOrAfter(moment('01.01.1900', "DD.MM.YYYY"))) {
      return createError({path, message: errorMessage})
    }

    if(!date.isBefore(moment((new Date()).toLocaleDateString('ru'), "DD.MM.YYYY"))) {
      return createError({path, message: errorMessage})
    }

    return (value.length === 10 && date.isValid() || createError({path, message: errorMessage}))
  })
})

Yup.addMethod(Yup.string, 'email', function () {
  return this.matches(/^ *[A-Za-z0-9а-яА-Я\._-]+@([A-Za-z0-9а-яА-Я]{1,2}|[A-Za-z0-9а-яА-Я]((?!(\.\.))[A-Za-z0-9а-яА-Я.-])+[A-Za-z0-9а-яА-Я])\.[A-Za-zа-яА-Я]{2,} *$/, {
    name: 'email',
    message: 'Некорректный email',
    excludeEmptyString: true,
  })
})
