<template>
  <div>
    <div class="user_data_head">

      <Avatar
          size="large"
          :image="store.state.user.profile.preview_avatar"
          v-fullscreen-image="{
            imageUrl: [store.state.user.profile.avatar],
            zoom: true,
          }"
      />

      <div style="display: flex; flex-flow: column; gap: 12px; align-items: flex-start;">
        <Typography variant="v16-700">
          {{ profile.first_name }} {{ profile.last_name }}
        </Typography>
        <!--        <div class="user_name"></div>-->
        <AppButton
            variant="secondary"
            size="small"
            @click="router.push({name: 'EditProfile'})"
        >
          <Icon :icon="require(`@/assets/icons/white_20_pen.svg`)"/>
          Редактировать
        </AppButton>

      </div>
    </div>

    <div class="locationAndAge">
      <UserLocationAndAge
          :city="profile.city"
          :birthDate="profile.birth_date"
      />

      <div v-if="profile.rating" class="score">
<!--        <Icon :icon="require(`@/assets/icons/gray_16_star.svg`)"/>-->
<!--        <Typography variant="body1">-->
<!--          {{ profile.rating }}-->
<!--        </Typography>-->
      </div>
    </div>

    <UserSpecializations
        style="margin-bottom: 8px; margin-top: 12px;"
        :specializations="profile.specializations"
    />

    <UserProfessions
        style="margin-bottom: 8px;"
        :professions="profile.professions"
    />

    <Typography variant="body1" preLine>
      {{ profile.about_me }}
    </Typography>

    <div class="location">
      <div class="location_top">

        <!--        <div class="score">-->
        <!--          <Icon :icon="require(`@/assets/icons/gray_16_star.svg`)"/>-->
        <!--          <Typography variant="body1">-->
        <!--            {{ user?.rating }}-->
        <!--          </Typography>-->
        <!--        </div>-->

        <!--        <span v-if="profile.rating">-->
        <!--          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">-->
        <!--            <path-->
        <!--                fill-rule="evenodd"-->
        <!--                clip-rule="evenodd"-->
        <!--                d="M5.15408 13.8882C4.81603 14.0646 4.40707 14.0336 4.09956 13.8081C3.79204 13.5826 3.63947 13.2019 3.70614 12.8264L4.2457 9.7338L1.97409 7.55689C1.69642 7.2921 1.59466 6.89182 1.71215 6.52657C1.82964 6.16131 2.14568 5.89543 2.52565 5.84218L5.67963 5.39132L7.10356 2.55081C7.27239 2.21015 7.61973 1.99463 7.99993 1.99463C8.38013 1.99463 8.72747 2.21015 8.8963 2.55081L10.3202 5.39132L13.4742 5.84218C13.8542 5.89543 14.1702 6.16131 14.2877 6.52657C14.4052 6.89182 14.3034 7.2921 14.0258 7.55689L11.7542 9.7338L12.2937 12.8271C12.3604 13.2025 12.2078 13.5832 11.9003 13.8087C11.5928 14.0342 11.1838 14.0653 10.8458 13.8889L7.99993 12.4182L5.15408 13.8882Z"-->
        <!--                stroke="#A6A5A5"-->
        <!--                stroke-linecap="round"-->
        <!--                stroke-linejoin="round"-->
        <!--            />-->
        <!--          </svg>-->
        <!--          {{ profile.rating }}-->
        <!--        </span>-->

      </div>

      <div class="description">
        {{ profile.description }}
      </div>
    </div>
  </div>
</template>

<script setup>

import store from '@/store/store'
import Avatar from '@/components/profile/Avatar.vue'
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import UserLocationAndAge from '@/components/UserLocationAndAge.vue'
import UserProfessions from '@/components/UserProfessions.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import AppButton from '@/components/UI/AppButton.vue'
import {router} from '@/router/router'
import UserSpecializations from "@/components/UserSpecializations.vue";

const profile = store.state.user.profile
</script>

<style lang="scss">

.user_data_head {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  .user_btn {
    margin-top: 12px;
    display: flex;
    height: 36px;
    min-width: 72px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    img {
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
    }

    span {
      //   font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }
  }
}

.locationAndAge {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 12px;
}

.score {
  display: flex;
  align-items: center;
}

</style>
