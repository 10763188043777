<template>
  <div class="pageWrapper">
    <head-new
        :back-action="() => router.push({
          name: state.SubscriptionPageSettings.returnName ?? 'Profile',
          params: state.SubscriptionPageSettings.params ?? {},
        })"
        title="Оформление подписки"
    />

    <div class="main_container pageWrapperOverflow">

      <div class="steps">
        <Typography variant="body2" color="#A6A5A5">
          Шаг {{ 1 }} из {{ 2 }}
        </Typography>

        <Logo variant="leadpay"/>
      </div>

      <BarProgress
          :completed="1"
          :barCount="2"
          color="#CF2E2E"
          style="margin-bottom: 24px"
      />

      <Typography variant="v24-700">
        Выбери тариф
      </Typography>

      <Typography variant="v14-400" color="#A6A5A5" style="margin-top: 6px; margin-bottom: 6px">
        Подписка на срок от 6 месяцев выгоднее на 20-36%
      </Typography>

      <FormError
          name="error"
          style="margin-bottom: 8px"
      />

      <Card style="margin-bottom: 8px">
        <Typography
            variant="v12-500"
            color="#D3D2D2"
            style="margin-bottom: 4px"
        >
          Younesis Free
        </Typography>

        <Typography variant="v16-600">
          Твой тариф сейчас
        </Typography>

        <SubscriptionFeaturesBlock
            title="Younesis Free"
            type="selection"
            style="margin-top: 12px;"
        />
      </Card>

      <Card
          v-for="subscriptionPrice in subscriptionPriceList"
          style="margin-bottom: 8px; position: relative"
          :variant="errors.error ? 'error' : 'secondary'"
      >
        <Typography
            v-if="subscriptionFeatures[subscriptionPrice.title]?.anotherName"
            variant="v14-500"
            style="padding: 4px 8px; border: 1px solid #CF2E2E; border-radius: 99px; display: inline-block; margin-bottom: 12px"
        >
          {{ subscriptionFeatures[subscriptionPrice.title].anotherName }}
        </Typography>

        <Typography
            v-if="subscriptionPrice.discounted_price && Number(subscriptionPrice.discounted_price) < Number(subscriptionPrice.price)"
            class="discountLabel"
            variant="v12-500"
        >
          {{
            `-${(((Number(subscriptionPrice.price) - Number(subscriptionPrice.discounted_price)) / Number(subscriptionPrice.price)) * 100) | 0}%`
          }}
        </Typography>

        <FormCircularRadio
            name="subscriptionPrice"
            :label="null"
            :option="subscriptionPrice.id"
            onlyInput
        >
          <div style="display: flex;justify-content: space-between">
            <Typography
                variant="v12-500"
                color="#D3D2D2"
            >
              {{ subscriptionPrice.title }}
            </Typography>
          </div>

          <div style="display: flex; align-items: center; gap: 8px; margin-top: 4px">
            <Typography variant="v16-600">
              {{ rubFormat.format(subscriptionPrice.final_price / subscriptionPrice.period_count) }}
              ₽/{{ singleSubscriptionPeriodLabel(subscriptionPrice.period) }}
            </Typography>
            <Typography v-if="subscriptionPrice.period_count > 1" variant="v12-400" color="#D3D2D2">
              {{ rubFormat.format(subscriptionPrice.final_price) }} ₽
            </Typography>
          </div>

          <Typography
              v-if="subscriptionPrice.period_count > 1"
              style="margin-top: 4px"
              variant="v12-400"
              color="#D3D2D2"
          >
            При оплате за {{ finalSubscriptionPeriodLabel(subscriptionPrice.period, subscriptionPrice.period_count) }}
          </Typography>

          <SubscriptionFeaturesBlock
              :title="subscriptionPrice.title"
              type="selection"
              style="margin-top: 12px; margin-left: -29px"
          />

        </FormCircularRadio>
      </Card>

      <appButton
          style="margin-top: 24px; margin-bottom: 24px"
          full-width
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"
      >
        Продолжить
      </appButton>

    </div>

  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import Typography from "@/components/UI/Typography.vue"
import BarProgress from "@/components/BarProgress.vue"
import Logo from "@/components/logo/YounesisLogo.vue"
import FormError from "@/components/form/FormError.vue"
import Card from "@/components/UI/Card.vue"
import {useForm} from "vee-validate"
import * as Yup from "yup"
import store from "@/store/store.js"
import FormCircularRadio from '@/components/form/FormCircularRadio.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {
  finalSubscriptionPeriodLabel,
  singleSubscriptionPeriodLabel,
  subscriptionFeatures,
} from '@/configs/subscriptionStatuses.js'
import {onBeforeMount, reactive} from 'vue'
import {router} from '@/router/router.js'
import SubscriptionFeaturesBlock from '@/views/subscriptions/SubscriptionFeaturesBlock.vue'

const subscriptionPriceList = store.state.user.directories.subscription_price_list.map(i => {
  i.final_price = i.discounted_price ?? i.price
  i.order = subscriptionFeatures[i.title]?.order
  return i
}).sort((a, b) => a.order - b.order)

const rubFormat = new Intl.NumberFormat('ru-RU', {
  maximumFractionDigits: 0,
})

const state = reactive({
  SubscriptionPageSettings: null,
})

onBeforeMount(() => {
  let raw = sessionStorage.getItem('SubscriptionPageSettings')
  if (!raw) {
    raw = JSON.stringify({
      subscriptionPriceId: null,
      returnName: 'Profile',
    })
    sessionStorage.setItem('SubscriptionPageSettings', raw)
  }
  state.SubscriptionPageSettings = JSON.parse(raw)
})

const {handleSubmit, setErrors, values, errors, isSubmitting, setValues} = useForm({
  validationSchema: Yup.object({
    error: Yup.mixed()
        .test('error', 'Выбери тариф', () => {
          return values.subscriptionPrice
        }),
  }),
})

const onSubmit = handleSubmit(async values => {
  sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
    subscriptionPriceId: values.subscriptionPrice,
    returnName: null,
  }))
  await router.push({name: 'SubscriptionPayment'})
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  min-height: calc(100dvh - 60px);
  max-height: calc(100dvh - 60px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  padding-bottom: 24px;
}

.discountLabel {
  background: #CF2E2E;
  padding: 2px 6px;
  border-radius: 20px;
  position: absolute;
  top: 14px;
  right: 14px;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

</style>
