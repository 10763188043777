<template>
  <!-- New navs start-->
  <div class="navigation">

    <div class="main_container">

      <router-link
          :to="{ name: 'Home'}"
          class="navLink"
          :class="{selected: route.meta.navigationTab === 'home', active: route.meta.navigationTab === 'home'}"
      >
        <HeaderHomeIcon
            class="headerIcon"
            :color="route.meta.navigationTab === 'home' ? selectedColor : defaultColor"
            :warning="true"
        />
        Главная
      </router-link>

      <router-link
          :to="{ name: 'CardHolder' }"
          class="navLink"
          :class="{selected: route.meta.navigationTab === 'social', active: route.meta.navigationTab === 'social'}"
      >
        <HeaderCardIcon
            class="headerIcon"
            :color="route.meta.navigationTab === 'social' ? selectedColor : defaultColor"
        />
        Визитница
      </router-link>

      <router-link
          :to="{ name: 'ChatList' }"
          class="navLink"
          :class="{selected: route.meta.navigationTab === 'chat', active: route.meta.navigationTab === 'chat'}"
      >
        <HeaderChatIcon
            class="headerIcon"
            :color="route.meta.navigationTab === 'chat' ? selectedColor : defaultColor"
        />
        Сообщения
      </router-link>

    </div>
  </div>
  <!-- New navs end -->
</template>

<script setup>
import {useRoute} from 'vue-router'
import HeaderHomeIcon from '@/components/Header/HeaderHomeIcon.vue'
import HeaderChatIcon from '@/components/Header/HeaderChatIcon.vue'
import HeaderCardIcon from '@/components/Header/HeaderCardIcon.vue'

const route = useRoute()

const selectedColor = '#FFFFFF'
const defaultColor = '#8B8B8B'

</script>

<style lang="scss">
@import "@/assets/variables";

.navigation {
  border-top: 1px solid #2b2b2b;
  background: #171717;
  position: fixed;
  display: flex;
  height: $bottomMenuOffset;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;

  .main_container {
    display: flex;
    padding: 12px 0 24px 0;

    & > .navLink {
      flex-basis: 100px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      color: #8B8B8B;
      gap: 6px;

      &.selected {
        color: #ffffff;
      }

      //&.active {
      //  .headerIcon {
      //    position: relative;
      //    margin: -12px;
      //    padding: 12px;
      //    //padding: 12px;
      //    border: 2px solid rgba(231, 94, 94, 0.24);
      //    border-radius: 12px;
      //    box-shadow: 0 0 16px 0 rgba(231, 94, 94, 0.3);
      //    background: rgb(207, 46, 46);
      //    color: rgb(139, 139, 139);
      //  }
      //}
    }
  }

  //.main_container {
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-between;
  //  padding: 12px 29px;
  //
  //  .navs_link {
  //    width: 48px;
  //    height: 48px;
  //    border-radius: 50%;
  //    display: flex;
  //    flex-direction: column;
  //    align-items: center;
  //    justify-content: center;
  //    color: rgb(139, 139, 139);
  //    font-size: 10px;
  //    font-weight: 500;
  //    line-height: 0;
  //    letter-spacing: 0;
  //    text-align: center;
  //    transition: 0.2s;
  //    transform: translateY(-2px);
  //
  //    &:hover {
  //      color: rgb(255, 255, 255);
  //
  //      .navs_link_img {
  //        svg {
  //          path {
  //            stroke: rgb(255, 255, 255);
  //          }
  //
  //          rect {
  //            stroke: rgb(255, 255, 255);
  //          }
  //        }
  //      }
  //    }
  //
  //    .navs_link_img {
  //      padding: 12px;
  //      border-radius: 12px;
  //      border: 2px solid rgba(231, 94, 94, 0);
  //      box-shadow: 0px 0px 16px 0px rgba(231, 94, 94, 0);
  //      background: rgba(207, 46, 46, 0);
  //      line-height: 0;
  //      transition: 0.2s;
  //
  //      svg {
  //        path {
  //          transition: 0.2s;
  //          stroke: rgb(139, 139, 139);
  //        }
  //
  //        rect {
  //          transition: 0.2s;
  //          stroke: rgb(139, 139, 139);
  //        }
  //      }
  //    }
  //  }
  //
  //  .selected {
  //    color: rgb(255, 255, 255);
  //    .navs_link_img {
  //      svg {
  //        path {
  //          stroke: rgb(255, 255, 255);
  //        }
  //
  //        rect {
  //          stroke: rgb(255, 255, 255);
  //        }
  //      }
  //    }
  //  }
  //
  //  .active {
  //    transform: translateY(-28px);
  //    font-size: 11px;
  //    font-weight: 600;
  //    letter-spacing: 0;
  //    text-align: center;
  //
  //    .navs_link_img {
  //      padding: 12px;
  //      border: 2px solid rgba(231, 94, 94, 0.24);
  //      border-radius: 12px;
  //      box-shadow: 0 0 16px 0 rgba(231, 94, 94, 0.3);
  //      background: rgb(207, 46, 46);
  //      color: rgb(139, 139, 139);
  //      margin-bottom: 14px;
  //      svg {
  //        path {
  //          stroke: rgb(255, 255, 255);
  //        }
  //        rect {
  //          stroke: rgb(255, 255, 255);
  //        }
  //      }
  //    }
  //  }
  //}
}
</style>
