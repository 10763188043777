import OnwardsStep1 from '@/views/profile/onwards/OnwardsStep1.vue'
import OnwardsStep2 from '@/views/profile/onwards/OnwardsStep2.vue'
import OnwardsStep3 from '@/views/profile/onwards/OnwardsStep3.vue'
import OnwardsStep4 from '@/views/profile/onwards/OnwardsStep4.vue'

export const onwardsSteps = {
  1: OnwardsStep1,
  2: OnwardsStep2,
  3: OnwardsStep3,
  4: OnwardsStep4,
}
