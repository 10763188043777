<template>
  <div>
    <div v-if="achievementId" class="modalWindow">
      <head-new
          :page_title="isCreate ? 'Добавить' : 'Редактировать'"
          :back-action="back"
      />

      <div class="main_container form">

        <FormTextAreaGrowable
            class="textArea"
            label="Кейс или достижение"
            name="text"
            :rows="4"
        />

        <Typography
            variant="v14-400"
            color="#D3D2D2"
            style="margin-bottom: 8px; margin-top: 4px"
        >
          Что пишут о себе другие:
        </Typography>

        <Card>
          <div style="display: flex; gap: 8px ;margin-bottom: 20px; align-items: center">
            <Avatar
                size="tiny"
                radius="8"
                :image="require(`@/assets/images/profile/avatar.png`)"
            />
            <Typography
                variant="v18-700"
            >
              Ксения Пазюк
            </Typography>
          </div>

          <div style="display: flex;gap: 6px; align-items: center; margin-bottom: 12px">
            <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_suitcase.svg`)"/>
            <Typography variant="subtitle1">
              Кейсы и достижения
            </Typography>
          </div>

          <Typography
              variant="v14-400"
              color="#D3D2D2"
              style="padding-bottom: 12px; border-bottom: 1px solid #303030"
          >
            Протипировала лично 856 красоток
          </Typography>
          <Typography
              variant="v14-400"
              color="#D3D2D2"
              style="padding-top: 12px; padding-bottom: 12px; border-bottom: 1px solid #303030"
          >
            Запустила 15 потоков школы стиля для себя
          </Typography>
          <Typography
              variant="v14-400"
              color="#D3D2D2"
              style="padding-top: 12px;"
          >
            За три года сама выросла от чека 10к до чека 200к
          </Typography>
        </Card>

        <div style="flex-grow: 1"/>

        <div class="buttons">
          <appButton
              full-width
              class="submitButton"
              :disabled="isSubmitting"
              :loading="isSubmitting"
              @click="onSubmit"
          >
            Сохранить
          </appButton>

          <appButton
              full-width
              class="submitButton"
              :disabled="isSubmitting"
              variant="secondary"
              @click="onDelete"
          >
            {{ isCreate || store.state.user.profile.achievements.length <= 1 ? 'Отменить' : 'Удалить' }}
          </appButton>
        </div>

        <BottomDrawer
            :is-shown="state.isDeleteId"
            title="Удаление данных"
            @close="state.isDeleteId = null"
        >
          <Typography variant="body1" color="#D3D2D2">
            Ты точно хочешь удалить кейс или достижение?
          </Typography>

          <div class="modalButtons">

            <appButton
                variant="secondary"
                @click="state.isDeleteId = null"
            >
              Оставить
            </appButton>

            <appButton
                variant="redSecondary"
                @click="deleteAchievement(state.isDeleteId)"
                color="#FF303A"
                :disabled="state.isDeleting"
                :loading="state.isDeleting"
            >
              Удалить
            </appButton>

          </div>
        </BottomDrawer>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {computed, nextTick, onMounted, reactive, watch} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import FormTextArea from '@/components/form/FormTextArea.vue'
import {useRoute} from 'vue-router'
import {appAxios} from '@/axios'
import Typography from '@/components/UI/Typography.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {useModalScrollingFix} from "@/hooks/useModalScrollingFix.js"
import {useModalHomeLayoutFix} from "@/hooks/useModalHomeLayoutFix.js"
import Card from "@/components/UI/Card.vue";
import Icon from "@/components/UI/Icon.vue";
import Avatar from "@/components/profile/Avatar.vue";
import FormTextAreaGrowable from "@/components/form/FormTextAreaGrowable.vue";

const route = useRoute()

const modalScrollingFix = useModalScrollingFix()
const modalHomeLayoutFix = useModalHomeLayoutFix()

const emit = defineEmits(['close'])

const {handleSubmit, values, isSubmitting, setFieldValue, setValues} = useForm({
  validationSchema: Yup.object({
    text: Yup.string().trim().label('Кейс или достижение').required().max(300),
  }),
});

const props = defineProps({
  achievementId: {
    type: String,
  },
})

watch(() => props.achievementId, (event) => {
  if (props.achievementId) {
    modalScrollingFix.open()
    modalHomeLayoutFix.open()
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === props.achievementId.toString())
    if (found) {
      requestAnimationFrame(() => {
        setValues({
          text: found.title,
        })
      })
    }
  } else {
    modalScrollingFix.close()
    modalHomeLayoutFix.close()
  }
})

function back() {
  emit('close')
}

const isCreate = computed(() => props.achievementId === 'new')

const state = reactive({
  isDeleteId: null,
  isDeleting: false,
})

const onDelete = async function () {
  if (isCreate.value || store.state.user.profile.achievements.length <= 1) {
    back()
  } else {
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === props.achievementId.toString())
    state.isDeleteId = found?.id
  }
}

const onSubmit = handleSubmit(async values => {
  if (isCreate.value) {
    const res = await appAxios.achievements.create(values.text)
  } else {
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === props.achievementId.toString())
    const res = await appAxios.achievements.update({
      ...found,
      title: values.text,
    })
  }
  const resResult = await appAxios.achievements.fetchAll()

  await store.commit('user/setProfile', {
    achievements: resResult.data,
  })

  back()
})

async function deleteAchievement(id) {
  state.isDeleting = true
  try {
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === id.toString())
    const res = await appAxios.achievements.remove(found)

    const resResult = await appAxios.achievements.fetchAll()

    await store.commit('user/setProfile', {
      achievements: resResult.data,
    })

    state.isDeleteId = null
    requestAnimationFrame(() => {
      back()
      state.isDeleting = false
    })
  } catch (e) {

  }
  state.isDeleting = false
}

</script>

<style lang="scss" scoped>

.modalWindow {
  position: fixed;
  z-index: 1001;
  background: #171717;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100dvh);
  max-height: calc(100dvh);
  overflow: auto;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.form {
  margin-top: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
    flex-basis: 100px;
  }
}

</style>
