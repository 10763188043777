<template>
  <Card
      class="card"
      :class="{isNotEnoughFilled: !profileProgress.isEnoughFilled}"
  >
    <Typography variant="subtitle1" style="margin-bottom: 4px;">
<!--      <template v-if="!profileProgress.isEnoughFilled">-->
<!--        Открой доступ ко всем функциям!-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        Для максимального результата-->
<!--      </template>-->
      Открой доступ ко всем функциям!
    </Typography>

    <Typography variant="body1" color="#fff" style="margin-bottom: 12px;">
<!--      <template v-if="!profileProgress.isEnoughFilled">-->
<!--        Следуй подсказкам, заполняй профиль и получай доступ к расширенным функциям приложения-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        Заполни оставшиеся разделы-->
<!--      </template>-->
      Заполни обязательные разделы, чтобы полноценно использовать приложение
    </Typography>

    <BarProgress
        :completed="!profileProgress.isEnoughFilled ? (profileProgress.enoughFilledCount) : (profileProgress.fullFilledCount)"
        :barCount="!profileProgress.isEnoughFilled ? profileProgress.enoughFields.length : profileProgress.fullFields.length"
        style="padding-bottom: 12px"
    />

    <AppButton
        full-width
        size="small"
        @click="navigate({name: 'CheckList'})"
    >
      Заполнить
    </AppButton>

  </Card>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import Card from '@/components/UI/Card.vue'
import AppButton from '@/components/UI/AppButton.vue'
import store from '@/store/store'
import {computed, reactive, watch} from 'vue'
import {router} from '@/router/router'
import BarProgress from "@/components/BarProgress.vue"

const profileProgress = computed(() => store.getters["user/profileProgress"])

const props = defineProps({
  progress: {
    type: String,
  }
})

function navigate(route) {
  router.push({
    ...route,
  })
}

</script>

<style lang="scss" scoped>

.card {
  margin-bottom: 16px;

  //&.isNotEnoughFilled {
  //  background: linear-gradient(105.04deg, #D53232 0%, #D6563A 100%);
  //}
}


</style>
